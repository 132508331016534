

(function ($) {
    $.fn.serializeFormJSON = function () {

        var o = {};
        var a = this.serializeArray();
        $.each(a, function () {
            if (o[this.name]) {
                if (!o[this.name].push) {
                    o[this.name] = [o[this.name]];
                }
                o[this.name].push(this.value || '');
            } else {
                o[this.name] = this.value || '';
            }
        });
        return o;
    };

})(jQuery);


$.validator.setDefaults({
    errorElement: 'span',
    errorClass: 'is-invalid',  
    errorPlacement: function(error, element) {
        if(element.parent('.input-group').length) {
            error.insertAfter(element.parent());
        } else {
            error.insertAfter(element);
        }
    }
});



function DropzoneLabel(){
    return `<div class="dz-details">
    <div class="dz-filename">
      <span data-dz-name></span>
    </div>
    <div class="dz-size" data-dz-size></div>
    <img data-dz-thumbnail />
    <div class="progress progress-striped active" role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="0">
      <div class="progress-bar progress-bar-success text-center" style="width: 0%; background-color: #002951" data-dz-uploadprogress></div>
    </div>
  </div>
  <div class="dz-error-message">
    <span data-dz-errormessage></span>
  </div>`;
}
